import React from 'react';

import SubblockWrapper from "./subblock-wrapper";
import KarriereList from "../karriere/karriereList";


const SubblockKarriereList =({content, position, fullwidth}) => {

  return (
    <>
      <SubblockWrapper position={position} fullwidth={fullwidth} colorTheme="dark">
        <KarriereList content={content} />
      </SubblockWrapper>
    </>
  );
}

export default SubblockKarriereList;
