import React, {useState} from "react";
import KarriereItem from "./karriereItem";
import {Accordion} from "react-bootstrap";
import RichText from "../basics/rich-text";

export const KarriereKeyContext = React.createContext(null);

const KarriereList= ({content : karriereList}) => {
  const [selectedKarriereKey, setSelectKarriereKey] = useState(null);

  return (
    <>
    <KarriereKeyContext.Provider value={{selectedKarriereKey, setSelectKarriereKey}}>
      <Accordion>
        {karriereList.map( (item,index) =>(
          <div key={item.id}>
            <KarriereItem eventKey={index} header={item.title}>
              <RichText content={item.text} />
            </KarriereItem>
          </div>
        ))}
      </Accordion>
    </KarriereKeyContext.Provider>
    </>
  );
}

export default KarriereList