import React, {useContext, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {KarriereKeyContext} from "./karriereList";
import {ArrowRight, CaretUpFill} from 'react-bootstrap-icons';


const ContextAwareToggle = ({ children, eventKey}) => {
  const [isActive, setActive] = useState(false)

  const {selectedKarriereKey, setSelectKarriereKey} = useContext(KarriereKeyContext);
  const isCurrentEventKey = selectedKarriereKey === eventKey;

  const decoratedOnClickOnKeyDown = (e) => {
    if (e.code !== undefined && e.code !== "Enter") return  // abort when pressed Key is not Enter-Key, prenvent open/close by Tab-Key
    decoratedOnClick(e)
  }

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => {
      // callback && callback(eventKey)
      if (isCurrentEventKey && isActive) {
        setSelectKarriereKey( null );
        setActive(false);
      } else {
        setSelectKarriereKey(eventKey);
        setActive(true);
      }
    },
  );

  const highlightClass = isCurrentEventKey ? " bg-primary " : "";
  const arrowRightClass = isCurrentEventKey ? " text-white " : " text-primary ";

  return (
    <>
      <h3 className={"h5 text-start text-white user-select-none"} >
        <span className={highlightClass+ " text-start cursor-pointer p-1"} onClick={decoratedOnClick} onKeyDown={decoratedOnClickOnKeyDown} role="button" tabIndex="0">
          <ArrowRight className={arrowRightClass+ "me-1 mb-1"}/>
          {children}
        </span>
      </h3>
    </>
  );
}

const KarriereItem = ({eventKey, header, children}) => {
  return (
    <>
      <ContextAwareToggle eventKey={eventKey}>{header}</ContextAwareToggle>
      <Accordion.Collapse eventKey={eventKey}>
        <>
          {/* Karriere polygon */}
          <div className="text-start pt-0 pb-0 px-4" style={{marginTop: "-4px", marginBottom: "-9px", width: "70px"}}>
            <CaretUpFill className={"text-white"} />
          </div>

          {/* Karriere textbox */}
          <div className="bg-white text-secondary text-start w-100 py-2 px-4 mb-3">
            <div>{children}</div>
          </div>
        </>
      </Accordion.Collapse>
    </>
  );
}

export default KarriereItem