import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockPageHeader from "../components/common/subblock/subblock-page-header";
import SubblockKarriereList from "../components/common/subblock/subblock-karriere-list";
import SubblockHeadlineWithSubtitle from "../components/common/subblock/subblock-headline-with-subtilte";
import SubblockRichText from "../components/common/subblock/subblock-rich-text";


const Karriere = ({ data }) => {
  const {header, title, subTitle, karriereList, textBewerbung, displayKarriereList, textWhenNotDisplayingKarriereList} = data.strapiKarriere

  return (
    <>
      <Seo title="Karriere" />
      <Layout>
        <SubblockPageHeader content={header} />
        <SubblockHeadlineWithSubtitle title={title} subTitle={subTitle} noBottom />
        {displayKarriereList && (<SubblockKarriereList content={karriereList} position="center" fullwidth />)}
        {!displayKarriereList && (<SubblockRichText content={textWhenNotDisplayingKarriereList} position="center" fullwidth />)}
        <SubblockRichText content={textBewerbung} position="center" fullwidth />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiKarriereQuery {
  strapiKarriere {
    header {
      ...fragmentHeader
    }
    title
    subTitle
    textBewerbung {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
    karriereList {
      ...fragmentKarriereList
    }
    displayKarriereList
    textWhenNotDisplayingKarriereList {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
  }
}
`

export default Karriere


